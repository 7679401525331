import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const usePath = () => {
  const { pathname, search } = useLocation();
  const [paths, setPaths] = useState<Array<string>>([]);
  const [params, setParams] = useState<any>({})

  useEffect(() => {
    const urls = pathname.split("/");

    setPaths(urls);
  }, [pathname]);

  useEffect(() => {
    const search_params: any = new URLSearchParams(search);
    const paramsObject: { [key: string]: string } = {};
    for (const [key, value] of search_params.entries()) {
      paramsObject[key] = value;
    }

    // console.log({paramsObject})
    setParams(paramsObject)
  }, [search]);


  return {
    paths,
    last: paths[paths.length - 1],
    params
  };
};

export default usePath;
