import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, Row, theme, transition } from "../../theme";
import { TbLayoutDashboardFilled } from "react-icons/tb";
import { ImPieChart } from "react-icons/im";
import { RiHistoryLine } from "react-icons/ri";
import { IoSettings } from "react-icons/io5";
import usePath from "../../hooks/usePath";
import path from "path";

const Navigation = () => {
  const [active, setActive] = useState<
    "home" | "history" | "analytics" | "settings" | null
  >(null);
  const { paths } = usePath();

  useEffect(() => {
    // console.log({ paths });
    if (paths.length > 1) {
      if (paths.length === 2 && paths[1] === "home") {
        setActive("home");
      } else if (paths.length > 2) {
        setActive(paths[2] as "history" | "analytics" | "settings");
      }
    }
  }, [paths]);

  return (
    <Container>
      <Logo src={require("../../assets/logo_min_dark.png")} alt="Logo" />
      <SubColumn>
        <NavBottom active={active === "home"} to="/home">
          <TbLayoutDashboardFilled />
        </NavBottom>
        <NavBottom active={active === "history"} to="/home/history">
          <RiHistoryLine />
        </NavBottom>
        <NavBottom active={active === "analytics"} to="/home/analytics">
          <ImPieChart />
        </NavBottom>
      </SubColumn>
      <div style={{ flex: 1 }} />
      <NavBottom active={active === "settings"} to="/home/settings">
        <IoSettings />
      </NavBottom>
    </Container>
  );
};

const Container = styled.nav`
  padding: 0.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    padding: 1%;
    order: 1;
  }
`;

const Logo = styled.img`
  width: 50px;
  height: 50px;
  /* padding: 2.5px; */
  /* background-color: ${theme.accent_gray}; */

  @media (max-width: 768px) {
    display: none;
  }
`;

const SubColumn = styled(Row)`
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin: 0;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const NavBottom = styled(Link)<{ active: boolean }>`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? theme.font : theme.accent_gray};
  border-radius: 15px;
  font-size: 30px;
  color: ${(props) => (props.active ? theme.background : "#9494A8")};
  transition: ${transition};

  &:hover {
    background-color: ${(props) => (props.active ? theme.font : theme.accent)};
  }
  @media (max-width: 768px) {
    &:hover {
      background-color: ${theme.font};
    }
  }
`;

export default Navigation;
