import React from "react";
import wavesBackground from "../../assets/svg/layered-waves-haikei.svg";
import wavesBackgroundBottom from "../../assets/svg//layered-waves-haikei-bottom.svg";
import styled from "styled-components";
import { Row, theme } from "../../theme";
import {
  BenefitsWrapper,
  Catch,
  CircularWrapper,
  Description,
  Title,
} from "./Introduction.section";
import { SiOpenstreetmap } from "react-icons/si";
import { TbBoxMultiple } from "react-icons/tb";

const Benefits = () => {
  return (
    <>
    <Wave />
    <Container>
      <div>
        <Catch
          style={{
            color: theme.background,
            fontSize: 40,
            margin: 0,
            lineHeight: 1.2,
            marginBottom: 10,
          }}
        >
          Available for
          <br />{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            all your
          </span>{" "}
          delivery needs
        </Catch>
        <span
          style={{ fontWeight: 500, color: theme.background, fontSize: 14 }}
        >
          Get your dream order fresh, steam
          <br /> hot at 20min break time.
        </span>
      </div>
      <img src={require("../../assets/taxi.png")} width={600} alt="Order" />
      <BenefitsWrapper style={{ color: theme.background }}>
        <Row>
          <CircularWrapper
            style={{ backgroundColor: theme.background, color: theme.font }}
          >
            <SiOpenstreetmap />
          </CircularWrapper>
          <div>
            <Title>Realtime Order Tracking</Title>
            <Description>
              Order and track items realtime, <br />
              using the efficient interface.
            </Description>
          </div>
        </Row>
        <LastItem>
          <CircularWrapper
            style={{ backgroundColor: theme.background, color: theme.font }}
          >
            <TbBoxMultiple />
          </CircularWrapper>
          <div>
            <Title>Multi Order</Title>
            <Description>
              Save cost by 60% by ordering <br />
              multi items on a single request.
            </Description>
          </div>
        </LastItem>
      </BenefitsWrapper>
    </Container>
    <WaveBottom />
    </>
  );
};

const Container = styled.div`
  padding: 0 4%;
  display: flex;
  position: relative;
  min-height: 200px;
  background-color: ${theme.font};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Wave = styled.div`
  background-image: url(${wavesBackground});
  background-size: cover;
  background-position: center;
  height: 150px;
`;

const WaveBottom = styled.div`
  background-image: url(${wavesBackgroundBottom});
  background-size: cover;
  background-position: center;
  height: 200px;
`;

const LastItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

export default Benefits;
