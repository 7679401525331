import React from "react";
import styled from "styled-components";
import { RowColumns, theme } from "../../theme";
import { Catch, Circle } from "./Introduction.section";

const Details = () => {
  return (
    <Container id="discounts">
      <RowColumns width="50%">
        <Card style={{ minHeight: 400, backgroundColor: "#FFCE53" }}>
          <Circle
            style={{
              top: "-10%",
              left: "80%",
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          />
          <Catch
            style={{
              color: theme.font,
              fontSize: 40,
              lineHeight: 1,
              margin: 0,
            }}
          >
            Cheap offers <br />
            and more!
          </Catch>
          <DicountImg src={require("../../assets/discount.png")} height={450} />
          <Catch
            style={{
              color: theme.font,
              fontSize: 30,
              lineHeight: 1,
              margin: 0,
              position: "absolute",
              bottom: "6%",
              left: "6%",
            }}
          >
            <span style={{ fontSize: 15, display: "block" }}>Hotline</span>
            <StyledLink href="tel:+233554644586">0554644586</StyledLink>
          </Catch>
        </Card>
      </RowColumns>
      <RowColumns width="50%" style={{ gap: 10 }}>
        <Card style={{ minHeight: 400, backgroundColor: theme.font }}>
          <Circle
            style={{
              top: "-20%",
              left: "-10%",
              backgroundColor: "rgba(255, 255, 255,0.2)",
            }}
          />
          <DicountImg src={require("../../assets/cheap.png")} height={450} />
          <CatchLocal
            style={{
              color: theme.background,
              fontSize: 40,
              lineHeight: 0.5,
              margin: 0,
              zIndex: 1,
            }}
          >
            <span style={{ fontSize: 15, display: "block" }}>As low as</span>{" "}
            <br />
            GH₵ 15.00
          </CatchLocal>
          <Catch
            style={{
              color: theme.background,
              fontSize: 30,
              lineHeight: 1,
              margin: 0,
              position: "absolute",
              bottom: "6%",
              left: "6%",
            }}
          >
            order nowwww!!!
          </Catch>
        </Card>
      </RowColumns>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10%;
  display: flex;
  gap: 4%;

  @media (max-width: 768px) {
    padding: 0 6%;
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 100%;
  border-radius: 25px;
  padding: 6%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const DicountImg = styled.img`
  position: absolute;
  top: -10%;
  right: -20%;
  z-index: 0 !important;

  @media (max-width: 768px) {
    right: -30%;
  }
`;

const CatchLocal = styled(Catch)`
  @media (max-width: 768px) {
    z-index: 2 !important;
    line-height: 15px !important;
  }
`;

const StyledLink = styled.a`
  font-size: 30px;
  text-decoration: none;
  color: ${theme.font};
  font-family: inherit !important;
`;


export default Details;
