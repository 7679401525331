import React from 'react'
import styled from 'styled-components';
import Navigation from '../components/Home/Navigation.component';
import WorkSpace from '../components/Home/WorkSpace.component';

const Home = () => {
  return (
    <Container>
        <Navigation />
        <WorkSpace />
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  display: flex;
  padding: 1%;
  width: 100%;
  overflow: hidden;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default Home