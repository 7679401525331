import React from "react";
import { IDeliverStatus, IDeliveryOrder } from "../../../types/interfaces";
import styled from "styled-components";
import { theme, transition } from "../../../theme";

const CurrentOrder = ({
  data,
  index,
  selected,
  setSelected,
}: {
  data: IDeliveryOrder;
  index: number;
  selected: IDeliveryOrder | null;
  setSelected: React.Dispatch<React.SetStateAction<IDeliveryOrder | null>>;
}) => {
  const getColorByStatus = (status: IDeliverStatus) => {
    return status === "In progress"
      ? theme.yellow
      : status === "Pending"
      ? theme.accent_gray
      : status === "Accepted"
      ? theme.orange
      : status === "Cancelled"
      ? theme.red_light
      : theme.green_light;
  };

  return (
    <Container
      onClick={() => setSelected(data)}
      hoverColor={getColorByStatus(data.status)}
      selected={selected?.id === data?.id}
    >
      <IconWrapper>
        <Icon
          alt="delivery item image"
          background={getColorByStatus(data.status)}
          src={
            data.image_urls.length > 0
              ? data.image_urls[0]
              : require("../../../assets/icons/delivery-bike.png")
          }
        />
      </IconWrapper>
      <div
        style={{
          display: "flex",
          flex: 1,
          borderBottom: "1px solid #EAEAEE",
          paddingBottom: 5,
        }}
      >
        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
          <Label>
            Start address: <Value>{data.address.start}</Value>
          </Label>
          <Label>
            End destination: <Value>{data.address.end}</Value>
          </Label>
          <Label>
            Items: <Value>{data.items}</Value>
          </Label>
        </div>
        <DetailsWrapper>
          <Label>
            <Status hoverColor={getColorByStatus(data.status)} index={index}>
              {data.status}
            </Status>
          </Label>

          <Value>GH₵ {data.estimated_price}</Value>
        </DetailsWrapper>
      </div>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean, hoverColor: string }>`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  transition: ${transition};
  cursor: pointer;
  border-right: ${(props) =>
    props.selected ? "2px solid " + props.hoverColor : "1px solid transparent"};
  padding-right: 5px;
  /* border-top-right-radius: 5px; */
  border-bottom-right-radius: 5px;
  align-items: flex-start;

  &:hover {
    border-right: 1px solid ${(props) => props.hoverColor};
  }

  @media (max-width: 768px) {
    padding-right: 5px;
  }
`;

const IconWrapper = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
`;

const Icon = styled.img<{ background: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  background-color: ${(props) => props.background};
`;

export const Label = styled.h6`
  font-size: 13px;
  color: #858599;
  margin: 0;
  font-weight: 500;
  display: inline;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

export const Value = styled(Label)`
  color: ${theme.font};
  font-size: 15px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const Status = styled(Value)<{ hoverColor: string; index: number }>`
  &.current-${(props) => props.index}:hover {
    color: ${(props) => props.hoverColor};
  }
`;

const DetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export default CurrentOrder;
