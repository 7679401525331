import styled from "styled-components";
import { theme, transition } from "../theme";

export const WidthController = styled.div`
  width: 400px;
  transition: ${transition};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  gap: 30px;
  align-items: center;
`;

export const Close = styled.button`
  border-radius: 50%;
  background-color: #f4f5f6;
  padding: 2px;
  border: none;
  outline: none;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  transition: ${transition};
  color: #737a8d;
  cursor: pointer;

  &:hover {
    background-color: ${theme.orange};
    color: ${theme.background};
  }
`;

export const Info = styled.p`
  font-size: 10px;
  color: #7d8088;
  font-style: italic;
  margin: 7px 0 20px 0;
`;

export const LinkWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background-color: #f4f5f6;
  padding: 4px 4px 4px 4px;
  width: 100%;
  margin-top: 15px;
  overflow: hidden;
`;

export const PasteButton = styled.button`
  border: 1px solid #e5e7eb;
  background-color: white;
  display: flex;
  outline: none;
  margin: 0;
  padding: 7px;
  align-items: center;
  font-size: 12px;
  gap: 4px;
  border-radius: 10px;
  color: ${theme.font};
  cursor: pointer;
  transition: ${transition};

  &:hover {
    border-color: ${theme.orange};
    background-color: ${theme.orange};
    color: ${theme.background};
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;