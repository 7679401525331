import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  Marker,
} from "@react-google-maps/api";
import mapStyles from "../../../assets/json/customMapViewDark.json";
import useExternalAPI from "../../../hooks/useExternalAPI";
import useGeoLocation from "../../../hooks/useLocation";

const MapSelect = ({
  location,
  submitLocation,
}: {
  location?: { address: string; latitude: number; longitude: number };
  submitLocation: (loc: {
    address: string;
    latitude: number;
    longitude: number;
  }) => void;
}) => {
  const default_position = {
    lat: 5.6037,
    lng: -0.187,
  };
  const [zoom, setZoom] = useState(15);
  const userLocation = useGeoLocation();
  const [user_current_location, setUserCurrentLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const [address, setAddress] = useState<string | null>(
    location?.address || null
  );

  const mapRef = useRef<google.maps.Map>();
  const { fetchData } = useExternalAPI();

  useEffect(() => {
    userLocation.requestPermission();
  }, []);

  useEffect(() => {
    const location = userLocation.location;
    // console.log({ location });
    if (location && location?.coords.latitude && location?.coords.longitude)
      setUserCurrentLocation({
        lat: location?.coords.latitude,
        lng: location?.coords.longitude,
      });
  }, [userLocation.location]);

  useEffect(() => {
    // console.log({ position, user_current_location });
    if (!position && user_current_location) {
      setPosition(user_current_location);
      handleZoom(15);
    }
  }, [user_current_location]);

  useEffect(() => {
    // console.log({location})
    if (location?.latitude && location.longitude) {
      setPosition({
        lat: location.latitude,
        lng: location.longitude,
      });
      handleZoom(15);
    }
    if (location?.address) setAddress(location.address);
  }, [location]);

  const handleZoomChange = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom || 10);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    try {
      const clickedPosition = event.latLng;
      const lat = clickedPosition?.lat();
      const lng = clickedPosition?.lng();
      if (lat && lng) {
        if (lat && lng) {
          setPosition({
            lat,
            lng,
          });
          getPlaceName(lat, lng);
          handleZoom(15);
        }
      }
    } catch (e) {
      console.error("Error handling click", e);
    }
  };

  const getPlaceName = async (lat: number, lng: number) => {
    try {
      const submit = await fetchData({
        url: `map/getPlaceName?lat=${lat}&lng=${lng}`,
        subUrl: "shared",
        method: "GET",
      });

      if (submit["status"]) {
        setAddress(submit["data"]);
        submitLocation({
          address: submit["data"],
          latitude: lat,
          longitude: lng,
        });
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
      return null;
    }
  };

  const handleZoom = (targetZoom: number) => {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom() || 10;
      const stepTime = 100;

      const zoomIn = (current: number) => {
        if (current < targetZoom) {
          mapRef.current!.setZoom(current + 1);
          setTimeout(() => zoomIn(current + 1), stepTime);
        }
      };

      const zoomOut = (current: number) => {
        if (current > targetZoom) {
          mapRef.current!.setZoom(current - 1);
          setTimeout(() => zoomOut(current - 1), stepTime);
        }
      };

      if (currentZoom < targetZoom) {
        zoomIn(currentZoom);
      } else if (currentZoom > targetZoom) {
        zoomOut(currentZoom);
      }
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      center={position || default_position}
      zoom={zoom}
      onLoad={(map) => {
        mapRef.current = map;
        handleZoomChange();
      }}
      onZoomChanged={handleZoomChange}
      options={{
        disableDefaultUI: true,
        styles: mapStyles,
      }}
      onClick={handleMapClick}
    >
      {user_current_location && (
        <Marker
          position={user_current_location}
          icon={{
            url: require("../../../assets/markers/current_pin.png"),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          title="Current location"
        />
      )}
      {position && (
        <Marker
          position={position}
          icon={{
            url: require("../../../assets/markers/clicked_pin.png"),
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          title="Pickup location"
        />
      )}
    </GoogleMap>
  );
};

export default MapSelect;
