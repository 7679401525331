import React from "react";
import styled from "styled-components";
import { MdError } from "react-icons/md";
import { theme, transition } from "../../theme";

const FormMessage = ({
  message,
  status,
}: {
  message?: string | null;
  status: "success" | "info" | "error";
}) => {
  return (
    <Container show={Boolean(message)} status={status}>
      <MdError size={20} /> {message}
    </Container>
  );
};

const Container = styled.div<{
  show: boolean;
  status: "success" | "info" | "error";
}>`
  height: ${(props) => (props.show ? "auto" : 0)};
  width: ${(props) => (props.show ? "100%" : 0)};
  overflow: hidden;
  transition: ${transition};
  border-radius: 5px;
  background-color: ${({ status }) =>
    status === "error" ? "#FDF3F3" : status === "info" ? "#e3e3e3" : "#dceed8"};
  color: ${({ status }) =>
    status === "error"
      ? "#EC3F3F"
      : status === "info"
      ? theme.font
      : "#37662d"};
  padding: ${(props) => (props.show ? "10px" : 0)};
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-line;
  margin: 5px 0;
`;

export default FormMessage;
