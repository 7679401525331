import React from "react";
import { trio } from "ldrs";

trio.register();

const Loader = ({ message, style, color = "black" }: { message: string; style?: React.CSSProperties | undefined; color?: string }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        ...style
      }}
    >
      <l-trio size="40" speed="1.3" color={color}></l-trio> please wait{" "}
      {message}..
    </div>
  );
};

export default Loader;
