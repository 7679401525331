import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import config from "../config";

type DriverLocation = {
  driverId: string;
  latitude: number;
  longitude: number;
};

type CustomUpdate = {
  [key: string]: any;
};

export const useSocket = (
  url: string = config.BASE_URL[config.ENV]
) => {
  const socketRef = useRef<Socket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [driverLocations, setDriverLocations] = useState<DriverLocation[]>([]);
  const [customUpdates, setCustomUpdates] = useState<CustomUpdate[]>([]);

  useEffect(() => {
    // Initialize the socket connection
    socketRef.current = io(url);

    // Handle socket connection
    socketRef.current.on("connect", () => {
      setIsConnected(true);
      console.log("Connected to socket server");
    });

    // Handle socket disconnection
    socketRef.current.on("disconnect", () => {
      setIsConnected(false);
      console.log("Disconnected from socket server");
    });

    // Clean up the socket connection on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [url]);

  const createOrJoinRoom = (roomName: string) => {
    if (socketRef.current) {
      socketRef.current.emit("createOrJoinRoom", roomName);
    }
  };

  const leaveRoom = (roomName: string) => {
    if (socketRef.current) {
      socketRef.current.emit("leaveRoom", roomName);
    }
  };

  const updateDriverLocation = (
    driverId: string,
    latitude: number,
    longitude: number
  ) => {
    if (socketRef.current) {
      const location: DriverLocation = { driverId, latitude, longitude };
      socketRef.current.emit("updateDriverLocation", location);
    }
  };

  const sendCustomUpdate = (update: CustomUpdate) => {
    if (socketRef.current) {
      socketRef.current.emit("customUpdate", update);
    }
  };

  useEffect(() => {
    const socket = socketRef.current;

    if (socket) {
      socket.on("driverLocationUpdated", (location: DriverLocation) => {
        setDriverLocations((prev) => [...prev, location]);
      });

      socket.on("customUpdateReceived", (update: CustomUpdate) => {
        setCustomUpdates((prev) => [...prev, update]);
      });
    }

    return () => {
      if (socket) {
        socket.off("driverLocationUpdated");
        socket.off("customUpdateReceived");
      }
    };
  }, []);

  return {
    isConnected,
    createOrJoinRoom,
    leaveRoom,
    updateDriverLocation,
    sendCustomUpdate,
    driverLocations,
    customUpdates,
  };
};
