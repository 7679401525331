import React, { useEffect, useState } from "react";
import { IoArrowBack, IoCall, IoCloseOutline } from "react-icons/io5";
import styled from "styled-components";
import {
  GlobalButton,
  GlobalTextInput,
  Row,
  TextInputRawWrapper,
  TextInputWrapper,
  theme,
  transition,
} from "../../../theme";
import countryCodes from "../../../utils/country_codes.json";
import { GoPaste } from "react-icons/go";
import Loader from "../Loader.component";
import { formHandler } from "../../../services/form_handler.service";
import useExternalAPI from "../../../hooks/useExternalAPI";
import FormMessage from "../FormMessage.component";
import MapPreview from "../Maps/MapPreview.component";
import OrderDetails from "../../Landing/OrderDetails.component";
import { useSocket } from "../../../hooks/useSocket";
import {
  EDeliveryStatus,
  getColorByStatus,
} from "../../../services/data_handler.service";
import { Value } from "../../Workspace/Dashboard/CurrentOrder.component";
import {
  Close,
  Info,
  LinkWrapper,
  PasteButton,
  TopSection,
  WidthController,
} from "../../../styles/modal.styles";
import CancelOrder from "./CancelOrder.component";
import Modal from "../Modal.component";

const QueryOrder: React.FC<{
  preload: {
    code: string;
    pickupPhone: string;
  };
  orderData: any;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderInfo: React.Dispatch<any>;
  setOrderData: React.Dispatch<any>
}> = ({ preload, setVisible, setCancelModal, setOrderInfo, orderData, setOrderData }) => {
  const [values, setValues] = useState<string>(preload.code);
  
  const [stage, setStage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState<{
    message: string | null;
    status: "success" | "info" | "error";
  }>({ message: null, status: "info" });
  const { fetchData } = useExternalAPI();
  const [roomName, setRoomName] = useState<string | null>(null);
  const {
    isConnected,
    createOrJoinRoom,
    leaveRoom,
    updateDriverLocation,
    sendCustomUpdate,
    driverLocations,
    customUpdates,
  } = useSocket();

  useEffect(() => {
    console.log({ isConnected });
  }, [isConnected]);

  useEffect(() => {
    if (orderData) {
      const roomName = orderData?.code + "-" + orderData?.pickup?.phone;
      setRoomName(roomName);
      createOrJoinRoom(roomName);
    } else {
      roomName && leaveRoom(roomName);
      setStage(1)
    }
  }, [orderData]);

  useEffect(() => {
    if (!loading)
      setTimeout(() => {
        setLoading(false);
      }, 10000);
  }, [loading]);

  useEffect(() => {
    setValues(preload.code);
  }, [preload.code]);

  const request = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = formHandler(e);
      console.log({ formData });
      const response = await fetchData({
        url: `delivery/order?code=${formData["code"]}&pickupPhone=${formData["pickupPhone"]}`,
        method: "GET",
      });
      if (response["status"]) {
        setStage(2);
        setOrderData(response["data"]);
        setFormMessage({
          status: "success",
          message: response["message"] || "Successful",
        });
        setTimeout(() => {
          setFormMessage({ message: null, status: "info" });
        }, 2000);
      } else setFormMessage({ status: "error", message: response["message"] });
    } catch (e: any) {
      // console.error({ e });
      setFormMessage({
        status: "error",
        message: e.message || "An error has occured",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <WidthController>
        <TopSection>
          <h4 style={{ margin: 0 }}>Oder Tracking Query</h4>
          <Close onClick={() => setVisible(false)}>
            <IoCloseOutline />
          </Close>
        </TopSection>
        <Row style={{ width: "100%", gap: 0 }}>
          <div
            style={{
              width: stage === 1 ? "100%" : 0,
              overflow: "hidden",
              height: stage === 1 ? "100%" : 0,
              transition: transition,
            }}
          >
            <Info>
              If you received a{" "}
              <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                shared url
              </strong>
              , load it from your browser url to auto input track data.
            </Info>
            <form onSubmit={(e) => request(e)}>
              <LinkWrapper>
                <GlobalTextInput
                  placeholder="Enter order code.."
                  style={{
                    padding: 0,
                    flex: 1,
                    background: "transparent",
                    border: "none",
                    borderRadius: 0,
                  }}
                  defaultValue={preload.code}
                  name="code"
                  value={values}
                  onChange={(e) => setValues(e.target.value)}
                />
                <PasteButton
                  onClick={() => {
                    try {
                      navigator.clipboard.readText().then((text) => {
                        setValues(text);
                        // console.log("Pasted content: ", text);
                      });
                    } catch (error) {
                      console.error("Error reading clipboard text:", error);
                    }
                  }}
                >
                  Paste
                  <GoPaste />
                </PasteButton>
              </LinkWrapper>
              <Info>
                This is the 5 lettered code you receive when you order a
                request.
              </Info>
              <TextInputWrapper
                style={{
                  padding: "10px 10px",
                  border: "1px solid #e5e7eb",
                  marginTop: 10,
                }}
              >
                <select
                  style={{
                    fontSize: 18,
                    background: "transparent",
                    border: "none",
                    width: 100,
                    outline: "none",
                    color: theme.font,
                  }}
                  value={"233"}
                >
                  {countryCodes.map((data, index) => (
                    <option
                      key={index}
                      value={data.code}
                    >{`${data.flag} +${data.code}`}</option>
                  ))}
                </select>
                <TextInputRawWrapper
                  placeholder="Enter sender's no."
                  type="number"
                  style={{ fontSize: 18 }}
                  minLength={9}
                  maxLength={9}
                  required={true}
                  defaultValue={preload.pickupPhone}
                  name="pickupPhone"
                />
              </TextInputWrapper>
              <Info>
                This is the number of the pickup person for your order.
              </Info>
              <FormMessage
                message={formMessage.message}
                status={formMessage.status}
              />
              {!loading && (
                <GlobalButton
                  style={{
                    borderRadius: 10,
                    //   padding: "7px 14px",
                    width: "100%",
                    backgroundColor: theme.font,
                    color: theme.background,
                    marginTop: 15,
                  }}
                  // onClick={() => setLoading(true)}
                  type="submit"
                >
                  Send
                </GlobalButton>
              )}
              {loading && (
                <Loader
                  message="querying order"
                  color={theme.orange}
                  style={{
                    marginTop: 10,
                    fontSize: 12,
                  }}
                />
              )}
            </form>
          </div>
          <div
            style={{
              width: stage === 2 ? "100%" : 0,
              overflow: "hidden",
              height: stage === 2 ? "100%" : 0,
              transition: transition,
            }}
          >
            {orderData && (
              <>
                <div
                  style={{
                    height: 200,
                    borderRadius: 10,
                    marginTop: 20,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <MapPreview
                    start={orderData?.pickup}
                    waypoints={orderData?.drop_offs}
                    orderDetails={orderData}
                  />
                </div>
                <OrderDetails
                  code={orderData["code"]}
                  insights={orderData["insights"]}
                  orderRoutes={orderData}
                />
                <Row
                  style={{
                    justifyContent: "space-between",
                    marginTop: 20,
                    alignItems: "center",
                  }}
                >
                  {stage === 2 && (
                    <GlobalButton
                      style={{
                        borderRadius: 10,
                        //   padding: "7px 14px",
                        width: "20%",
                        backgroundColor: theme.font,
                        color: theme.background,
                      }}
                      type="button"
                      onClick={() => {
                        setOrderData(null);
                        setStage(1);
                      }}
                    >
                      <IoArrowBack size={15} />
                    </GlobalButton>
                  )}
                  {stage === 1 ? (
                    <GlobalButton
                      style={{
                        borderRadius: 10,
                        //   padding: "7px 14px",
                        width: "100%",
                        backgroundColor: getColorByStatus(orderData?.status),
                        color: theme.font,
                        marginTop: 15,
                      }}
                      // onClick={() => setLoading(true)}
                      type="submit"
                    >
                      {orderData?.status?.replace("_", " ")}
                    </GlobalButton>
                  ) : orderData?.status === EDeliveryStatus.PENDING ? (
                    <GlobalButton
                      style={{
                        borderRadius: 10,
                        //   padding: "7px 14px",
                        width: "100%",
                        color: theme.red_light,
                      }}
                      background={theme.accent_gray}
                      hoverColor={theme.accent}
                      onClick={() => {
                        setOrderInfo(orderData)
                        setVisible(false)
                        setCancelModal(true)}}
                      type="button"
                    >
                      Cancel Order
                    </GlobalButton>
                  ) : null}
                  {stage !== 1 &&
                    ![
                      EDeliveryStatus.PENDING,
                      EDeliveryStatus.CANCELLED,
                    ].includes(orderData?.status) && (
                      <div
                        style={{
                          height: 35,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <img
                          src={require("../../../assets/delivery-man.png")}
                          style={{ height: 40, width: 40 }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{
                              margin: 0,
                              padding: 0,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            {orderData?.courier?.ref?.username}
                          </span>
                          <span style={{ fontSize: 12, margin: 0 }}>
                            {orderData?.courier?.ref?.phone}
                          </span>
                        </div>
                      </div>
                    )}
                  {(orderData?.status === EDeliveryStatus.IN_PROGRESS ||
                    orderData?.status === EDeliveryStatus.CONFIRMED) && (
                    <GlobalButton
                      style={{
                        borderRadius: 10,
                        //   padding: "7px 14px",
                        width: "20%",
                        backgroundColor: theme.green_light,
                        color: theme.background,
                      }}
                      type="button"
                      onClick={() => {
                        orderData?.courier?.ref?.phone &&
                          window.open(`tel:${orderData?.courier?.ref?.phone}`);
                      }}
                    >
                      <IoCall size={15} color={theme.font} />
                    </GlobalButton>
                  )}
                </Row>
              </>
            )}
          </div>
        </Row>
      </WidthController>
    </>
  );
};

export default QueryOrder;
