import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GlobalButton,
  GlobalButtonPlain,
  Row,
  theme,
  transition,
} from "../../theme";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoMdCall } from "react-icons/io";
import { SearchWrapper } from "../../components/Home/Profile.section";
import { IDeliveryOrder } from "../../types/interfaces";
import CurrentOrder from "../../components/Workspace/Dashboard/CurrentOrder.component";

import {
  TbSquareRoundedChevronRightFilled,
  TbSquareRoundedChevronLeftFilled,
} from "react-icons/tb";

const Dashboard = () => {
  const [currentDate, setCurrentDate] = useState({
    dayNumber: new Date().getDate(),
    dayWord: new Date().toLocaleString("en-us", { weekday: "short" }),
    month: new Date().toLocaleString("en-us", { month: "long" }),
  });
  const current: IDeliveryOrder[] = [
    {
      id: 0,
      image_urls: [
        "https://www.zdnet.com/a/img/resize/a423a6f916db19efae20b0d749e728a8319a309b/2023/09/12/29be3e47-9f70-4dbe-aa37-58719088b48f/old-iphones-apple-store.jpg?auto=webp&fit=crop&height=1200&width=1200",
      ],
      address: {
        start: "Asafotu Close",
        end: "Madina, Estate",
      },
      status: "In progress",
      estimated_price: 54,
      items: 2
    },
    {
      id: 1,
      image_urls: [],
      address: {
        start: "Odorkor, Awoshie",
        end: "Tesano, Accra",
      },
      status: "Accepted",
      estimated_price: 31,
      items: 1
    },
    {
      id: 2,
      image_urls: [],
      address: {
        start: "Kaneshie, Accra",
        end: "Dansoman, Accra",
      },
      status: "Cancelled",
      estimated_price: 42,
      items: 1
    },
    {
      id: 3,
      image_urls: [],
      address: {
        start: "Tema, Community 1",
        end: "Sakumono, Tema",
      },
      status: "Completed",
      estimated_price: 63,
      items: 4
    },
  ];
  const [selected, setSelected] = useState<IDeliveryOrder | null>(null);

  useEffect(() => {
    setCurrentDate({
      dayNumber: new Date().getDate(),
      dayWord: new Date().toLocaleString("en-us", { weekday: "short" }),
      month: new Date().toLocaleString("en-us", { month: "long" }),
    });
  }, []);

  return (
    <WorkSpaceContainer>
      <ActionWrapper>
        <DateRow>
          <DateWrapper>{currentDate.dayNumber}</DateWrapper>
          <DateP>
            {currentDate.dayWord.split(" ").slice(0, 3).join(" ")},
            <br />
            {currentDate.month}
          </DateP>
          <GlobalButton
            style={{
              borderRadius: 30,
              gap: 10,
              color: theme.background,
              marginRight: "auto",
              margin: 0,
            }}
            background={theme.orange}
            hoverColor={theme.font}
          >
            Request Order <HiArrowLongRight size={20} />
          </GlobalButton>
        </DateRow>
        <HelpWrapper>
          <div>
            <HelpHeader>Hey, Need help? 👋</HelpHeader>
            <HelpDesc>Just ask support anything!</HelpDesc>
          </div>
          <DateWrapper style={{ backgroundColor: theme.background }}>
            <IoMdCall />
          </DateWrapper>
        </HelpWrapper>
      </ActionWrapper>
      <CurrentSection>
        <LeftSection>
          <Card style={{ paddingBottom: 0 }}>
            <CardTitle>Current Orders</CardTitle>
            <div>
              {current.slice(0, 4).map((data, index) => (
                <CurrentOrder
                  data={data}
                  key={index}
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))}
            </div>
          </Card>
        </LeftSection>
        <RightSection>
          <Card>
            <CardTitle style={{ display: "flex" }}>
              Order Tracking
              <MapSwitchButtons style={{ marginLeft: "auto" }}>
                <TbSquareRoundedChevronLeftFilled />
              </MapSwitchButtons>
              <MapSwitchButtons>
                <TbSquareRoundedChevronRightFilled />
              </MapSwitchButtons>
            </CardTitle>
          </Card>
          
        </RightSection>
      </CurrentSection>
    </WorkSpaceContainer>
  );
};

const WorkSpaceContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`;

const DateRow = styled(Row)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ActionWrapper = styled(Row)`
  justify-content: space-between;
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 1%;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 30px;
    padding: 0 10px 0 0;
  }
`;

const HelpHeader = styled.h5`
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  color: ${theme.font};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const HelpDesc = styled.p`
  font-size: 16px;
  color: #9a9a9a;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const DateWrapper = styled(SearchWrapper)`
  height: 70px;
  width: 70px;
  font-size: 22px;
`;

const DateP = styled.p`
  font-size: 14px;
  color: ${theme.font};
  border-right: 1px solid #9a9a9a;
  padding-right: 15px;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

const HelpWrapper = styled(Row)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const CurrentSection = styled(Row)`
  gap: 1%;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  align-items: flex-start;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const LeftSection = styled.div`
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const CardTitle = styled.h3`
  font-size: 18px;
  margin: 0;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const MapSwitchButtons = styled(GlobalButtonPlain)`
  font-size: 30px;
  color: #9494a8;
  transition: ${transition};

  &:hover {
    color: ${theme.font};
  }
`;

export default Dashboard;
