import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import mapStyles from "../../../assets/json/customMapViewDark.json";
import { ILocationAddress } from "../../../types/interfaces";
import { EDeliveryStatus } from "../../../services/data_handler.service";

const MapPreview = ({
  start,
  waypoints,
  orderDetails,
}: {
  start: ILocationAddress;
  waypoints: ILocationAddress[];
  orderDetails?: any;
}) => {
  const default_position = {
    lat: 5.6037,
    lng: -0.187,
  };
  const [direction, setDirection] =
    useState<google.maps.DirectionsResult | null>(null);
  const mapRef: any = useRef<google.maps.Map>();

  useEffect(() => {
    if (start && waypoints && waypoints.length > 0) {
      if (orderDetails?.status === EDeliveryStatus.PENDING) {
        setPendingDirections(start, waypoints);
      } else {
        const origin = orderDetails?.courier?.location || start;
        setInProgressDirections(origin, waypoints);
      }
    } else {
      console.error("Error: 'origin' and 'waypoints' values are missing", {
        start,
        waypoints,
      });
    }
  }, [start, waypoints, orderDetails]);

  const setPendingDirections = (
    start: ILocationAddress,
    waypoints: ILocationAddress[]
  ) => {
    const stops = waypoints.map((stop) => ({
      location: { lat: stop.lat, lng: stop.lng },
      stopover: true,
    }));

    const directionsServiceOptions: google.maps.DirectionsRequest = {
      destination: {
        lat: waypoints[waypoints.length - 1].lat,
        lng: waypoints[waypoints.length - 1].lng,
      },
      origin: { lat: start.lat, lng: start.lng },
      travelMode: google.maps.TravelMode.DRIVING,
      waypoints: stops,
    };

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      directionsServiceOptions,
      (
        result: google.maps.DirectionsResult | null,
        status: google.maps.DirectionsStatus
      ) => {
        if (status === "OK" && result) {
          setDirection(result);
        }
      }
    );
  };

  const setInProgressDirections = (
    origin: ILocationAddress,
    waypoints: ILocationAddress[]
  ) => {
    let destination: any;

    if (orderDetails?.status === EDeliveryStatus.CONFIRMED) {
      // If status is CONFIRMED, use the start as the destination and courier location as origin
      destination = { lat: start.lat, lng: start.lng };
      origin =
        waypoints.find(
          (waypoint) => waypoint.status === EDeliveryStatus.CONFIRMED
        ) || origin;
    } else {
      const started = waypoints.some(
        (waypoint) => waypoint.status === EDeliveryStatus.IN_PROGRESS
      );
      destination = started
        ? waypoints.find(
            (data: ILocationAddress) =>
              data.status === EDeliveryStatus.IN_PROGRESS
          )
        : origin;
    }

    if (destination) {
      const directionsServiceOptions: google.maps.DirectionsRequest = {
        destination: {
          lat: destination.lat,
          lng: destination.lng,
        },
        origin: { lat: origin.lat, lng: origin.lng },
        travelMode: google.maps.TravelMode.DRIVING,
      };

      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        directionsServiceOptions,
        (
          result: google.maps.DirectionsResult | null,
          status: google.maps.DirectionsStatus
        ) => {
          if (status === "OK" && result) {
            setDirection(result);
          }
        }
      );
    } else {
      console.error("Error: 'destination' could not be determined", {
        waypoints,
      });
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(start.lat, start.lng));
      waypoints.forEach((waypoint) => {
        bounds.extend(
          new window.google.maps.LatLng(waypoint.lat, waypoint.lng)
        );
      });
      mapRef.current.fitBounds(bounds);
    }
  }, [direction]);

  const fitBoundsToMarkers = () => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(start.lat, start.lng));
      waypoints.forEach((waypoint) => {
        bounds.extend(
          new window.google.maps.LatLng(waypoint.lat, waypoint.lng)
        );
      });
      if (orderDetails?.courier?.location) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderDetails.courier.location.lat,
            orderDetails.courier.location.lng
          )
        );
      }

      mapRef.current.fitBounds(bounds, { padding: 100 }); // Add padding

      // Set a minimum zoom level
      const listener = mapRef.current.addListener("idle", () => {
        if (mapRef.current.getZoom() > 15) {
          mapRef.current.setZoom(15);
        }
        window.google.maps.event.removeListener(listener);
      });
    }
  };

  useEffect(() => {
    fitBoundsToMarkers();
  }, [direction, waypoints, orderDetails?.courier?.location, start]);

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      // center={position || default_position}
      // zoom={zoom}
      onLoad={(map) => {
        mapRef.current = map;
        fitBoundsToMarkers();
        // handleZoomChange();
      }}
      // onIdle={fitBoundsToMarkers}
      // onZoomChanged={handleZoomChange}
      options={{
        // disableDefaultUI: true,
        styles: mapStyles,
        minZoom: 3, // Set a minimum zoom level
        maxZoom: 18, // Set a maximum zoom level
      }}
    >
      {start && (
        <Marker
          position={{ lat: start.lat, lng: start.lng }}
          icon={{
            url: require("../../../assets/markers/pickup_pin.png"),
            scaledSize: new window.google.maps.Size(20, 20),
          }}
          title="Pickup location"
        />
      )}
      {direction && (
        <DirectionsRenderer
          directions={direction}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: "#FFD897",
              strokeWeight: 1,
            },
          }}
        />
      )}
      {orderDetails?.courier?.location && (
        <Marker
          position={{
            lat: orderDetails?.courier?.location?.lat,
            lng: orderDetails?.courier?.location?.lng,
          }}
          icon={{
            url: require("../../../assets/markers/motorcycle.png"),
            scaledSize: new window.google.maps.Size(35, 35),
          }}
          title="Courier location"
        />
      )}
      {waypoints.map((data, index) => (
        <Marker
          key={index}
          position={{ lat: data.lat, lng: data.lng }}
          icon={{
            url:
              data?.status === EDeliveryStatus.PENDING
                ? require("../../../assets/markers/clicked_pin.png")
                : data?.status === EDeliveryStatus.CONFIRMED
                ? require("../../../assets/markers/mid_pin.png")
                : data?.status === EDeliveryStatus.CANCELLED
                ? require("../../../assets/markers/end_pin.png")
                : data?.status === EDeliveryStatus.IN_PROGRESS
                ? require("../../../assets/markers/mid_pin.png")
                : data?.status === EDeliveryStatus.DELIVERED
                ? require("../../../assets/markers/start_pin.png")
                : require("../../../assets/markers/clicked_pin.png"),
            scaledSize: new window.google.maps.Size(20, 20),
          }}
          title={`${data.address}`}
        />
      ))}
    </GoogleMap>
  );
};

export default MapPreview;
