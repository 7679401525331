import React, { useEffect, useState } from "react";
import {
  Close,
  Info,
  TopSection,
  WidthController,
} from "../../../styles/modal.styles";
import { IoCloseOutline } from "react-icons/io5";
import {
  GlobalButton,
  Row,
  TextInputWrapper,
  theme,
  transition,
} from "../../../theme";
import { FaCircleDot, FaRegCircleDot } from "react-icons/fa6";
import useExternalAPI from "../../../hooks/useExternalAPI";
import FormMessage from "../FormMessage.component";
import Loader from "../Loader.component";

const CancelOrder: React.FC<{
  preload: {
    code: string;
    phone: string;
  };
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ preload, setVisible }) => {
  const [active, setActive] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState<{
    message: string | null;
    status: "success" | "info" | "error";
  }>({ message: null, status: "info" });
  const [customReason, setCustomReason] = useState("");
  const { fetchData } = useExternalAPI();
  const [complete, setComplete] = useState<boolean>(false);
  const reasons = [
    "Changed Mind",
    "Found Another Service",
    "Delivery Delay",
    "Other",
  ];

  const request = async () => {
    setLoading(true);
    setFormMessage({status: "info", message: null})

    try {
      if (active === 3 && customReason === "") {
        setFormMessage({
          status: "error",
          message: "Custom reason can't be empty for 'other' selecton",
        });
        return;
      }

      if (active === null) {
        setFormMessage({
          status: "error",
          message: "Please input a reason for cancellation",
        });
        return;
      }

      const reason = active === 3 ? customReason : reasons[active];

      // console.log({reason})

      const response = await fetchData({
        url: `delivery/cancel`,
        method: "DELETE",
        body: { ...preload, cancelled_reason: reason },
      });
      if (response["status"]) {
        setComplete(true)
        // setFormMessage({
        //   status: "success",
        //   message: response["message"] || "Successful",
        // });
      } else setFormMessage({ status: "error", message: response["message"] });
    } catch (e: any) {
      // console.error({ e });
      setFormMessage({
        status: "error",
        message: e.message || "An error has occured",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if (!preload.code || !preload.phone) setVisible(false)
  }, [preload])

  return (
    <WidthController>
      <TopSection>
        <h4 style={{ margin: 0 }}>Cancel Order</h4>
        <Close onClick={() => setVisible(false)}>
          <IoCloseOutline />
        </Close>
      </TopSection>
      {!complete ? (
        <>
          <Row>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                height: "100%",
                transition: transition,
              }}
            >
              <Info>
                Please{" "}
                <strong style={{ color: theme.orange, fontStyle: "italic" }}>
                  select or type your reason
                </strong>{" "}
                for order cancellation.
              </Info>
            </div>
          </Row>
          <div>
            {reasons.map((data, index) => (
              <Row
                key={index}
                style={{
                  fontSize: 14,
                  border: `1px solid ${
                    active === index ? theme.orange : "#c4c4c4"
                  }`,
                  backgroundColor: active === index ? "#fef2ee" : "transparent",
                  padding: 10,
                  borderRadius: 10,
                  marginBottom: 10,
                  cursor: "pointer",
                  transition: transition,
                  fontWeight: active === index ? "bold" : "normal",
                }}
                onClick={() => setActive(index)}
              >
                {active === index ? (
                  <FaCircleDot color={theme.orange} />
                ) : (
                  <FaRegCircleDot color="#c4c4c4" />
                )}
                {data}
              </Row>
            ))}
            {active === 3 && (
              <TextInputWrapper>
                <textarea
                  rows={5}
                  style={{
                    width: "100%",
                    resize: "none",
                    border: "none",
                    outline: "none",
                    padding: "10px",
                    fontSize: "14px",
                    fontFamily: "inherit",
                    backgroundColor: "transparent",
                    color: theme.font,
                  }}
                  placeholder="Please describe your reason here..."
                  value={customReason}
                  onChange={(e) => setCustomReason(e.target.value)}
                />
              </TextInputWrapper>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
          }}
        >
          <img
            src={require("../../../assets/question.png")}
            height={60}
            width={60}
            style={{ marginTop: 20 }}
          />
          <p style={{ fontSize: 16, textAlign: "center" }}>
            Your delivery order has been successfully cancelled. We look forward
            to serving you again in the future!
          </p>
        </div>
      )}
      <FormMessage message={formMessage.message} status={formMessage.status} />
      {loading && (
        <Loader
          message="cancelling order"
          color={theme.orange}
          style={{
            marginTop: 10,
            fontSize: 12,
          }}
        />
      )}
      {!loading && !complete && (
        <GlobalButton
          style={{
            borderRadius: 10,
            //   padding: "7px 14px",
            width: "100%",
            color: "white",
            marginTop: 20,
          }}
          background={theme.font}
          hoverColor={theme.red_light}
          onClick={() => request()}
          type="button"
        >
          Submit Request
        </GlobalButton>
      )}
    </WidthController>
  );
};

export default CancelOrder;
