import { useState, useEffect } from 'react';

type LocationState = {
  location: GeolocationPosition | null;
  address: string | null;
  requestPermission: () => void;
  error: GeolocationPositionError | null;
};

const useGeoLocation = (): LocationState => {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [error, setError] = useState<GeolocationPositionError | null | any>(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation(position);
          setError(null);
          // reverseGeocode(position.coords.latitude, position.coords.longitude);
        },
        (err) => {
          setError(err);
          setLocation(null);
          setAddress(null);
        }
      );
    } else {
      setError({ code: 0, message: 'Geolocation is not supported' });
    }
  };

  // const reverseGeocode = (lat: number, lng: number) => {
  //   const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAP_UNRESTRICTED_KEY?.toString()}`;

  //   fetch(geocodingUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.results.length > 0) {
  //         setAddress(data.results[0].formatted_address);
  //       } else {
  //         setAddress(null);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       setAddress(null);
  //     });
  // };

  const requestPermission = () => {
    // console.log("Requesting location permission"); // Added log for requesting location permission
    navigator.permissions
      .query({ name: 'geolocation' })
      .then((permission) => {
        // console.log("Permission state:", permission.state); // Added log for permission state
        if (permission.state === 'granted') {
          getLocation();
        } else if (permission.state === 'prompt') {
          // console.log("Permission prompt detected"); // Added log for permission prompt
          permission.onchange = () => {
            // console.log("Permission state changed to:", permission.state); // Added log for permission state change
            if (permission.state === 'granted') {
              getLocation();
            } else {
              setError({ code: 1, message: 'Permission denied' });
            }
          };
        } else if (permission.state === 'denied') {
          // console.log("Permission denied"); // Added log for permission denied
          // Trigger permission request if permissions are off
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation(position);
              setError(null);
              // reverseGeocode(position.coords.latitude, position.coords.longitude);
            },
            (err) => {
              setError(err);
              setLocation(null);
              setAddress(null);
            }
          );
        } else {
          // console.log("Unknown permission state"); // Added log for unknown permission state
          setError({ code: 1, message: 'Permission denied' });
        }
      })
      .catch((err) => {
        console.error('Error requesting permission:', err); // Added error log for permission request error
        setError({ code: 2, message: err.message });
      });
  };

  useEffect(() => {
    requestPermission();
  }, []);

  return { location, address, error, requestPermission };
};

export default useGeoLocation;
