import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../theme";
import Navigation from "../components/Landing/Navigation.section";
import Introduction from "../components/Landing/Introduction.section";
import Catrgories from "../components/Landing/Catrgories.section";
import Benefits from "../components/Landing/Benefits.section";
import Details from "../components/Landing/Details.section";
import Footer from "../components/Landing/Footer.section";
import About from "../components/Landing/About.section";

const Landing = () => {
  return (
    <Container>
      <Navigation />
      <Introduction />
      <About />
      <Catrgories />
      <Benefits />
      <Details />
      <Footer />
    </Container>
  );
};

const Container = styled.nav`
  background-color: ${theme.background};
  min-height: 100vh;
  padding-top: 130px;
  background: none;
  font-size: 20px;
  width: 100vw;
  overflow-x: hidden;
`;

export default Landing;
