import {  ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "./AuthContext";
import useCookies from "../hooks/useCookies";
import { tryParseJSON } from "../services/data_handler.service";

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // const { setUser, user, removeValue } = useLocalStorage("cms-session", {});
  const { setCookie, deleteCookie, getCookie } = useCookies();
  const [user, setUser] = useState<object | null>(null);

  useEffect(()=>{
    if (user)
      setCookie('user', user)
  }, [user])

  // call this function when you want to authenticate the user
  const login = async (data: object, tokens: object) => {
    setCookie("session", tokens);
    setUser(data)
  };

  // call this function to sign out logged in user
  const logout = async () => {
    deleteCookie("session");
    deleteCookie("user");
    // navigate("/signin", { replace: true });
  };

  const value = useMemo(
    () => ({
      user: tryParseJSON(getCookie("user")),
      setUser,
      login,
      logout,
      children,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
