import React, { useEffect, useRef, useState } from "react";

import { Autocomplete, LoadScript } from "@react-google-maps/api";
import styled from "styled-components";
import { MdLocationPin } from "react-icons/md";
import { theme } from "../../theme";
import { TfiMapAlt } from "react-icons/tfi";
import { PasteButton } from "../../styles/modal.styles";

interface PlacesAutocompleteInputProps {
  apiKey?: string;
  placeholder?: string;
  value?: string;
  showMap?: boolean;
  toggleMapView?: (show:boolean) => void;
  onLocationChange: (location: google.maps.places.PlaceResult | null) => void;
}

const PlacesAutocompleteInput: React.FC<PlacesAutocompleteInputProps> = ({
  value = undefined,
  showMap = undefined,
  placeholder = "Enter address",
  onLocationChange,
  toggleMapView
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        onLocationChange(null);
        return;
      }
      onLocationChange(place);
    }
  };

  useEffect(()=>{
    if(value && inputRef.current) {
      if(inputRef.current) {
        inputRef.current.value = value;
      }
    }
  }, [value])

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <InputContainer>
        <MdLocationPin color={theme.orange} size={20} />
        <GlobalTextInput
          ref={inputRef}
          //   value={value || inputRef.current?.value }
          placeholder={placeholder}
          style={{
            padding: 0,
            background: "transparent",
            border: "none",
            borderRadius: 0,
            flex: 1,
          }}
        />
        <MapPaste onClick={() => toggleMapView ? toggleMapView(!showMap) : null} style={{margin: 0}} type="button">
          {showMap ? "hide" : ""} map
          <TfiMapAlt />
        </MapPaste>
      </InputContainer>
    </Autocomplete>
    // </LoadScript>
  );
};

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1;
  height: 100%;
  width: 310px;
  /* background-color: red; */
  overflow: hidden;
`;

const GlobalTextInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
  padding: 7px;
  font-size: 16px;
  outline: none;
  background: transparent;
`;

const MapPaste = styled(PasteButton)`
  position: absolute;
  right: 5px;
  top: 3.5px;
`

export default PlacesAutocompleteInput;
