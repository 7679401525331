export function generateGoogleMapsUrl({
  origin = "here",
  waypoints,
}: {
  origin?: string;
  waypoints: string[];
}): string {
  const baseUrl = "https://www.google.com/maps/dir/?api=1";

  // Check if there's only one waypoint
  if (waypoints.length === 1) {
    return `${baseUrl}&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(waypoints[0])}`;
  } else {
    const formattedWaypoints = waypoints
      .slice(0, waypoints.length - 1)
      .map(encodeURIComponent)
      .join("|");
    return `${baseUrl}&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(
      waypoints[waypoints.length - 1]
    )}&waypoints=${formattedWaypoints}`;
  }
}

// Example usage
//   const origin = "Yilo Krobo, Ghana";
//   const waypoints = ["Sogakofe, Ghana", "Kumasi, Ghana"];

//   const url = generateGoogleMapsUrl(origin, waypoints);

//   console.log(url);
