import React from "react";
import styled from "styled-components";
import { HeaderRow } from "./Catrgories.section";
import { Catch } from "./Introduction.section";
import { theme } from "../../theme";
import ImageGrid from "./ImageGrid.section";

const About = () => {
  return (
    <Container id="categories">
      <HeaderLocal
        style={{ flexDirection: "column", alignItems: "center", gap: 20 }}
      >
        <Catch
          style={{
            fontSize: 50,
            margin: 0,
          }}
        >
          About{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>US</span>
        </Catch>
        <Para style={{ fontWeight: 500, color: "#5A5A5A", textAlign: "center" }}>
          At Cutting-Edge Delivery Service (CED), we're about more than just
          deliveries—we're about creating an exceptional experience. Based in{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Accra, Ghana
          </span>
          , we offer the fastest and most reliable service to ensure your
          packages arrive swiftly and safely.
          <br />
          <br />
          With{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            advanced technology
          </span>{" "}
          and real-time tracking, our professional riders ensure your deliveries
          are quick and handled with care. From food to documents, we've got you
          covered.
          <br />
          <br />
          Enjoy convenient services like{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            multi-order savings
          </span>{" "}
          and doorstep pickups.
        </Para>
      </HeaderLocal>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const HeaderLocal = styled(HeaderRow)`
  padding: 0 10%;

  @media (max-width: 768px) {
    padding: 0 6%;
  }
`;

const Para = styled.p`
    margin: 0;
    padding: 0;
    font-size: 20px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`

export default About;
