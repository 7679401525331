const MAX_DELIVERIES = 15;
const env: "production" | "localhost" = "production";

export default {
  MAX_DELIVERIES,
  BASE_URL: {
    localhost: "http://localhost:5050",
    production: "https://ced-services-422722.uc.r.appspot.com",
  },
  ENV: env,
};
