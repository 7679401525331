import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalButton, Row, theme, transition } from "../../theme";
import { HiLocationMarker } from "react-icons/hi";
import useGeoLocation from "../../hooks/useLocation";
import { HiArrowLongRight } from "react-icons/hi2";
import useExternalAPI from "../../hooks/useExternalAPI";

const Profile = () => {
  const { location, requestPermission } = useGeoLocation();
  const [address, setAddress] = useState<string | null>(null);
  const { fetchData } = useExternalAPI();

  useEffect(() => {
    console.log({ location });
    getUserAddress(location);
  }, [location]);

  const getUserAddress = async (user_location: GeolocationPosition | null) => {
    try {
      if (
        !user_location ||
        !user_location?.coords.latitude ||
        !user_location?.coords.longitude
      ) {
        throw Error("User coordinates error.");
      }

      console.log({ user_location });

      const submit = await fetchData({
        url: `map/getPlaceName?lat=${user_location?.coords.latitude}&lng=${user_location?.coords.longitude}`,
        subUrl: 'shared',
        method: "GET",
      });

      if (submit["status"]) setAddress(submit["data"]);
    } catch (e) {
      console.error("An error occured while getting your location: ", e);
    }
  };

  return (
    <Container>
      <SearchContainer onClick={() => (!address ? requestPermission() : null)}>
        <LocationContainer>
          <HiLocationMarker color={theme.orange} size={20} />
        </LocationContainer>
        {`${address || "Your location..."}`}
      </SearchContainer>
      <Row>
        <ProfileImg src={require("../../assets/account_2.png")} alt="profile" />
        <div>
          <Name>Kojo Ntow</Name>
          <Contact>020-347-7216</Contact>
        </div>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  padding: 0.5% 1.5%;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 7.5px;
    justify-content: space-between;
  }
`;

const SearchContainer = styled(Row)`
  cursor: pointer;
  white-space: nowrap;
  color: ${theme.font};
  font-size: 14px;
  transition: ${transition};

  &:hover {
    color: ${theme.font};
  }

  @media (max-width: 768px) {
    font-size: 12px;
    white-space: pre-wrap;
  }
`;

export const SearchWrapper = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${transition};

  &:hover {
    border: 1px solid ${theme.yellow};
  }

  @media (max-width: 768px) {
    height: 45px;
    width: 45px;
  }
`;


const LocationContainer = styled(SearchWrapper)`
  @media (max-width: 768px) {
    border: none;
  }
`

const ProfileImg = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 15px;
  border: 1px solid ${theme.background};
  background-color: ${theme.accent_gray};
  transition: ${transition};
  cursor: pointer;

  /* &:hover {
    border: 1px solid ${theme.font};
  } */

  @media (max-width: 768px) {
    height: 45px;
    width: 45px;
  }
`;

const Name = styled.h5`
  font-size: 18px;
  font-weight: 500;
  color: ${theme.font};
  margin: 0 0 2px 0;

  @media (max-width: 768px) {
    min-width: 80px;
    font-size: 14px;
    white-space: pre-wrap;
  }
`;

const Contact = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.font};
  margin: 0;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export default Profile;
