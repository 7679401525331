import React from "react";
import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";
import NotFound404 from "../../assets/json/404.json";
import styled from "styled-components";
import { Catch } from "../Landing/Introduction.section";
import { GlobalButton, Link, Row, theme } from "../../theme";

const NotFound = () => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Container>
      <div
        style={{
          justifySelf: "flex-end",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Catch style={{ marginBottom: 10, textAlign: "right" }}>
          Sorry!... <br />{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Page
          </span>{" "}
          not found
        </Catch>
        <span style={{ fontWeight: 500 }}>
          The page you're looking for doesn't exists
          <br /> or an error occurred go back to home page
        </span>
        <Link to={"/"}>
          <GlobalButton
            style={{
              padding: "10px 30px",
              marginTop: 20,
              backgroundColor: theme.font,
              color: theme.background,
              fontSize: 17,
            }}
          >
            Go Home
          </GlobalButton>
        </Link>
      </div>
      <div>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: NotFound404,
          }}
          height={isBigScreen ? 500 : 300}
          width={isBigScreen ? 500 : 300}
        />
      </div>
      <span style={{ fontSize: 13, fontWeight: 500, position: "fixed", bottom: "5%", width: '100%', textAlign: 'center' }}>
        <strong>CED</strong> @{" "}
        <span
          style={{
            fontWeight: 600,
            fontFamily: '"Yrsa", serif',
            color: theme.orange,
            fontSize: 20,
          }}
        >
          {new Date().getFullYear()}
        </span>{" "}
        | All Rights Reserved
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export default NotFound;
