import { useAuth } from "../context/AuthProvider";
import config from "../config";

interface IRequest {
  baseUrl?: string;
  subUrl?: "client" | "shared";
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: object;
}

const useExternalAPI = () => {
  const { user, setUser, logout } = useAuth();

  const sharedUrl = config.BASE_URL[config.ENV] + "/api";

  const credentials = {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa(
      process.env.REACT_APP_BASE_AUTH_USERNAME +
        ":" +
        process.env.REACT_APP_BASE_AUTH_PASSWORD
    )}`,
  };

  const updateToken = (tokens: string) => {
    setUser({ ...user, tokens });
  };

  const refreshToken = async (request: RequestInfo, options: RequestInit) => {
    const refreshResponse = await fetch(`${sharedUrl}/user/refresh`, {
      method: "GET",
      headers: { ...credentials, sessionid: user.tokens?.refreshToken },
    });

    if (refreshResponse.ok) {
      const {
        data: { tokens },
      } = await refreshResponse.json();
      updateToken(tokens);
      return fetch(request, {
        ...options,
        headers: { ...options.headers, sessionid: tokens.accessToken },
      });
    } else {
      logout();
      return Promise.reject(new Error("Session expired"));
    }
  };

  const fetchData = async (request: IRequest) => {
    const {
      url,
      method = "GET",
      subUrl = "client",
      body,
      baseUrl = sharedUrl,
    } = request;
    const options: RequestInit = {
      method,
      headers: {
        ...credentials,
        ...(user?.tokens && { sessionid: user.tokens.accessToken }),
      },
      ...(body && { body: JSON.stringify(body) }),
    };

    try {
      const response = await fetch(`${baseUrl}/${subUrl}/${url}`, options);
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await response.json() : null;

      if (response.status === 401 && user?.tokens) {
        return refreshToken(`${baseUrl}/${url}`, options);
      }

      if (!response.ok) {
        throw new Error(data?.message || "An error occurred");
      }

      return data;
    } catch (error) {
      console.error("There was an error!", error);
      throw error;
    }
  };

  return { fetchData, formBaseUrl: "http://localhost:5050/setup" };
};

export default useExternalAPI;
