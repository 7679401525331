import styled from "styled-components";
import Profile from "./Profile.section";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../../pages/Workspace/Dashboard.page";
import NotFound from "../Shared/NotFound.component";
import History from "../../pages/Workspace/History.page";
import Analytics from "../../pages/Workspace/Analytics.page";

const WorkSpace = () => {
  // const locationChange = (e: google.maps.places.PlaceResult | null) => {
  //   console.log({ "new location": e });
  // };

  return (
    <>
      <Container>
        <Profile />
        <WorkSpaceContainer>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/history" element={<History />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </WorkSpaceContainer>
      </Container>
    </>
  );
};

const Container = styled.nav`
  flex: 1;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WorkSpaceContainer = styled.div`
  flex: 1;
  /* background-color: #f8f8f8; */
  width: 100%;
  /* border-radius: 30px; */
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default WorkSpace;
