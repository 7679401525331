import React from "react";
import { Catch } from "./Introduction.section";
import { Row, theme } from "../../theme";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

const Catrgories = () => {
  return (
    <Container id="categories">
      <HeaderRow>
        <span style={{ fontWeight: 500, color: "#5A5A5A" }}>
          It's not just about delivering items,
          <br /> we deliver you experience.
        </span>
        <Catch style={{ textAlign: "right", fontSize: 50, margin: 0 }}>
          Our{" "}
          <span style={{ color: theme.orange, fontFamily: "inherit" }}>
            Best Delivery
          </span>
          <br />
          Categories
        </Catch>
      </HeaderRow>
      <CategoriesWrapper>
        <CatItem>
          <CatImage
            src={require("../../assets/images/market.jpg")}
            style={{
              backgroundColor: theme.accent,
            }}
            alt="delivery guy"
          />
          <CatName>Food & Beverages</CatName>
          {/* <Link to="/signin">
            Order Now <FaAngleRight />
          </Link> */}
        </CatItem>
        <CatItem>
          <CatImage
            src={require("../../assets/images/handling.jpg")}
            style={{
              backgroundColor: "#e8efff",
            }}
            alt="delivery guy"
          />
          <CatName>Documents & Files</CatName>
          {/* <Link to="/signin">
            Order Now <FaAngleRight />
          </Link> */}
        </CatItem>
        <CatItem>
          <CatImage
            src={require("../../assets/images/electronic.jpg")}
            style={{
              backgroundColor: "#eaeaea",
            }}
            alt="delivery guy"
          />
          <CatName>Electrical Gadgets</CatName>
          {/* <Link to="/signin">
            Order Now <FaAngleRight />
          </Link> */}
        </CatItem>
      </CategoriesWrapper>
    </Container>
  );
};

export const Container = styled.div`
  margin-top: 70px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 70px;
  align-items: flex-end;
  padding: 0 6%;

  @media (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
    padding: 0 20px;
  }
`;

const CategoriesWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-around;
  gap: 20px;
  padding: 0 6%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const CatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const CatName = styled.h3`
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.orange};
  display: flex;
  align-items: flex-end;
  gap: 7.5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CatImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: "cover";

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

export default Catrgories;
