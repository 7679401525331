import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const colors = {
  light: {
    background: "#FBFBFB",
    font: "#23222A",
  },
  dark: {
    background: "#2B2B2B",
    font: "#FBFBFB",
  },
};

let theme = {
  ...colors["light"],
  yellow: "#FCA311",
  orange: "#FD5729",
  accent: "#FFF5F3",
  accent_gray: '#F7F7F9',
  green_light: '#80ed99',
  red_light: '#e63946',
  gray: "#858599"
};

// Styled components

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

interface RowColumnsProps {
  width?: string;
}

const RowColumns = styled.div<RowColumnsProps>`
  width: ${(props) => props.width || "auto"};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const transition = "0.5s all ease-in-out";

const TextInputRaw = styled.input`
  border: none;
  outline: none;
  margin: 0;
  background: transparent;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const GlobalTextInput = styled.input`
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  padding: 7px;
  font-size: 16px;
  outline: none;
`;

const GlobalButton = styled.button<{hoverColor?: string; background?: string}>`
  padding: 13px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  transition: ${transition};
  background-color: ${(props)=> props.background || "transparent"};
  cursor: pointer;
  color: ${theme.font};
  border: none;

  &:hover {
    background-color: ${(props)=> props.hoverColor || theme.accent};
    /* border-color: ${theme.orange}; */
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const GlobalButtonPlain = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  cursor: pointer;
`

const Link = styled(RouterLink)`
  text-decoration: none;
`;

const TextInputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  background-color: #f4f5f6;
  padding: 4px 4px 4px 7px;
  width: 100%;
`;

const TextInputRawWrapper = styled(GlobalTextInput)`
  padding: 0;
  flex: 1;
  background: transparent;
  border: none;
  border-radius: 0;
`;

export {
  colors,
  theme,
  Row,
  transition,
  RowColumns,
  TextInputRaw,
  GlobalButton,
  Link,
  TextInputWrapper,
  TextInputRawWrapper,
  GlobalButtonPlain
};
