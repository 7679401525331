import { useCallback } from 'react';

export interface CookieValue {
  [key: string]: any;
}

interface CookieOptions {
    path?: string;
    domain?: string;
    expires?: Date;
    httpOnly?: boolean;
    secure?: boolean;
    sameSite?: 'strict' | 'lax' | 'none';
  }

const useCookies = () => {
  const getCookie = useCallback((name: string): string | null => {
    const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
    return cookieValue ? decodeURIComponent(cookieValue.pop() || '') : null;
  }, []);

  const setCookie = useCallback(
    (name: string, value: CookieValue, options: CookieOptions = {}) => {
      const cookieOptions = {
        path: '/',
        ...options,
      };

      if (options.expires && options.expires instanceof Date) {
        cookieOptions.expires = options.expires.toUTCString() as unknown as Date;
      }

      const updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(JSON.stringify(value));

      let cookieString = updatedCookie;
      for (const optionKey in cookieOptions) {
        cookieString += '; ' + optionKey;
        const optionValue = cookieOptions[optionKey as keyof CookieOptions];
        if (optionValue !== true) {
          cookieString += '=' + optionValue;
        }
      }

      document.cookie = cookieString;
    },
    []
  );

  const updateCookie = useCallback(
    (name: string, value: CookieValue, options: CookieOptions = {}) => {
      const existingCookie = getCookie(name);
      if (existingCookie) {
        const parsedCookie = JSON.parse(existingCookie);
        const updatedCookie = { ...parsedCookie, ...value };
        setCookie(name, updatedCookie, options);
      } else {
        setCookie(name, value, options);
      }
    },
    [getCookie, setCookie]
  );

  const deleteCookie = useCallback(
    (name: string) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    []
  );

  return { getCookie, setCookie, updateCookie, deleteCookie };
};

export default useCookies;